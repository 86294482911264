
import { defineComponent } from 'vue';
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src

export default defineComponent({
	name: 'HomeView',
	components: {
		// HelloWorld,
	},

	mounted() {
		window.location.href="https://loot.fun/home.php";
	}
});
