<template>
	<div className="Content">
		<router-view/>
	</div>
</template>

<script>
	import { emitter } from '@/emitter';
	import { logt, sleep } from '@/LogUtils';
	import axios from 'axios';

	export default {
		name: 'App',
		components: {

		},
		data() {
			return {
				TAG: `App`,
			}
		},

		mounted() {


		}
	}
</script>

<style>
	#app {

	}

	.adBanner {
		height: 2px;
		width: 2px;
		visibility: hidden;
	}

	.Content {
		/* background-image: url('./assets/images/background.jpg'); */
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		background-color:#efefef;
		overflow:auto;
		width:100vw;
		height:100vh;
		min-height: -webkit-fill-available;
		/* position:fixed; */
		display:flex;
		flex-direction:column;
		align-items: center;
		/* justify-content: flex-start; */
		
		justify-content: center;
		position: relative;
	}

	
	/* @media all and (orientation: landscape) {
		.Content {
			justify-content: center;
		}
	} */
</style>
