import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/file/',
		name: 'noFile',
		component: () => import(/* webpackChunkName: "about" */ '@/views/FileView.vue')
	},
	{
		path: '/file/:identifier',
		name: 'file',
		component: () => import(/* webpackChunkName: "about" */ '@/views/FileView.vue')
	},
	{
		path: '/file/:identifier/:page',
		name: 'filePage',
		component: () => import(/* webpackChunkName: "about" */ '@/views/FileView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
